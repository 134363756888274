import { cloneDeep } from 'lodash'
import { getMountingName, normalizeSectorParamsView } from '@/utils/index'

export const mountings = {
  ballast: 'Балластная кровля',
  mechanical: 'Механическая фиксация'
}

export const mountingTypes = {
  ballast: 'ballast',
  mechanical: 'mechanical'
}

export const materialTypes = {
  reinforced: 'Армированная стяжка',
  granite: 'Гранитный щебень'
}

const getCurrentMaterial = (insulationMaterials, id) => {
  return insulationMaterials.find(material => material.id === id)
}

const getCurrentMaterialType = (insulationMaterials, materialId, materialTypeId) => {
  const material = getCurrentMaterial(insulationMaterials, materialId)
  return material.types.find(type => type.id === materialTypeId)
}

const getMaterial = (materials, id) => {
  return materials.find(material => material.id === id)
}

export const prepareSectorsForPdf = (
  location,
  sectors,
  resultSectors,
  insulationMaterials,
  allMaterials,
  mountingTypes
) => {
  const { zone, type, pressure, pressureUnit, coefficient } = location
  return sectors.map((sector, sectorIndex) => {
    const materialType =
      sector.base.hasOwnProperty('type') && !sector.base.type.isCustom
        ? getCurrentMaterialType(insulationMaterials, sector.base.id, sector.base.type.id).name
        : ''
    const materialSize = getCurrentMaterial(insulationMaterials, sector.base.id).size ?? ''
    const base = `${
      getCurrentMaterial(insulationMaterials, sector.base.id).name
    } ${materialType} ${materialSize}`
    // prettier-ignore
    const { paramK, paramE, paramV, paramAeroC, paramAeroD, paramAeroE, side, waterproofFixation } =
      resultSectors[sectorIndex]
    const { isDoubleFastener = false } = sectors[sectorIndex].base.type || {}
    const originalMaterial = getMaterial(allMaterials, sector.material.id)
    const header = {
      zone,
      type,
      pressure,
      pressureUnit,
      coefficient,
      paramK,
      paramE,
      paramV,
      paramAeroC,
      paramAeroD,
      paramAeroE,
      isDoubleFastener,
      sectorName: sector.sectorName
    }
    const additionalParams = {}
    if (sector.base.hasOwnProperty('type')) {
      additionalParams.step = sector.base.type.size
    }

    const checkedWaterproofFixation = Array.isArray(waterproofFixation)
      ? waterproofFixation
      : Object.values(waterproofFixation)

    const waterproofFixationToPdf = cloneDeep(checkedWaterproofFixation)
      .filter(item => item)
      .flatMap(item => [...item])

    const data = {
      header,
      sideInfo: side,
      material: `${originalMaterial.name} ${originalMaterial.thickness}`,
      image: atob(sector.sectorImage),
      sectorName: sector.sectorName,
      sectionType: sector.material.mounting,
      sectorParams: {
        base,
        height: sector.building.height,
        buildingParams: normalizeSectorParamsView(sector),
        sideB: sector.sideB,
        sideL: sector.sideL,
        mounting: getMountingName(mountingTypes, sector),
        ...additionalParams
      },
      waterproofFixation: getOrdered(waterproofFixationToPdf, orderWaterproofFixation)
    }

    if (location.converted !== null) {
      data.pressure = {}
      data.pressure.pa = location.converted
    }

    return data
  })
}

export const prepareTotalMaterialsTableForPdf = (materialsTable, allSectors, allMaterials) => {
  const clonedTable = cloneDeep(materialsTable)
  const updatedClonedTable = clonedTable.map(item => {
    return {
      sectors: item.sectors.map(sector => {
        return {
          ...sector,
          sectorNumber: allSectors.findIndex(s => s.uid === sector.uid) + 1,
          materialName: allMaterials.find(material => material.id === item.id).name
        }
      })
    }
  })
  return updatedClonedTable.flatMap(item => item.sectors)
}

function getOrdered(array, glossary) {
  const ordered = []

  array.forEach((item) => {
    const index = orderWaterproofFixation[item.title]
    if (index || index === 0) {
      ordered[index] = item
    } else {
      ordered.push(item)
    }
  })

  return ordered.filter((item) => item)
}

const orderWaterproofFixation = {
  'Площадь зоны': 0,
  'Ветровое давление': 1,
  'Ветровое давление * 1,35': 2,
  'Тип теплоизоляции': 3,
  'Количество крепежа теплоизоляции на 1м²': 4,
  'Средне кол-во крепежа теплоизоляции на 1м²': 5,
  'Тип гидроизоляции': 6,
  'Несущая способность': 7,
  'Рекомендуемая ширина рулона': 8,
  'Расстояние м/у рядами крепления': 9,
  'Шаг крепежа': 10,
  'Количество крепежа гидроизоляции на 1м²': 11,
  'Среднее кол-во крепежа гидроизоляции на 1м²': 12,
  'Двойной крепеж': 13
}
