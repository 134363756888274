<template>
  <addons :links="plugins" is-plugins> Плагины </addons>
</template>

<script>
import Addons from '@/components/smart/Addons'
const plugins = [
  {
    id: 0,
    name: 'Плагин для Autocad',
    link: 'https://nav.tn.ru/bim/plagin-tekhnonikol-dlya-autocad/'
  }
  // {
  //   id: 1,
  //   name: 'Плагин для Revit',
  //   link: 'https://nav.tn.ru/services/bim/klin-10-prilozhenie-dlya-formirovaniya-uklonov-na-ploskoy-krovle-s-pomoshchyu-klinovidnoy-izolyatsii/',
  // },
]

export default {
  name: 'Plugins',
  components: { Addons },
  computed: {
    plugins: () => plugins
  }
}
</script>

<style lang="sass" scoped></style>
