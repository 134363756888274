<template>
  <div :class="wrapperClasses">
    <div class="addons__title">
      <slot></slot>
    </div>
    <div class="addons__items">
      <a
        :href="album.link"
        class="link"
        target="_blank"
        v-for="album in currentAlbums"
        :key="album.id"
      >
        <img :src="linkIcon" alt="download" class="link__icon" />
        {{ album.name }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    isInDetails: Boolean,
    links: Array,
    isPlugins: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      allAlbums: state => state.albums,
      result: state => state.result
    }),
    sectorId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorResultFromState() {
      return this.$store.getters.getSectorResult(this.sectorId)
    },
    albums() {
      return this.isInDetails ? this.sectorResultFromState.albums : this.links
    },
    currentAlbums() {
      return this.isPlugins
        ? this.links
        : this.allAlbums.filter(album => this.albums.includes(album.id))
    },
    linkIcon() {
      return this.isPlugins
        ? require('@/assets/img/cog-red.svg')
        : require('@/assets/img/download.svg')
    },
    wrapperClasses() {
      return {
        addons: true,
        plugins: this.isPlugins
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.addons
  $w-s: calc(100% - 48px)
  $w-l: rem(960)
  +media((max-width: (0: $w-s, 992: $w-l)))
  +media((margin: (0: rem(32) auto, 768: rem(51) auto rem(34))))
  &.plugins
    .addons
      &__items
        +media((grid-template-columns: (320: 1fr, 576: repeat(2, 1fr), 992: repeat(3, 1fr))))
  &__title
    @extend %20
    font-weight: bold
    text-align: left
    +media((margin-bottom: (0: rem(15), 960: rem(34))))
  &__items
    display: grid
    width: 100%
    +media((margin: (320: 0 auto, 960: 0)))
    +media((grid-template-columns: (320: 1fr, 992: repeat(2, 1fr))))
    .link
      background: $semi-white
      border: rem(1) solid $ec
      box-sizing: border-box
      cursor: pointer
      display: flex
      flex-direction: row
      align-items: center
      font-style: normal
      font-weight: 500
      font-size: rem(14)
      line-height: rem(22)
      color: $black
      transition: .5s
      text-decoration: none
      text-align: left
      padding: 0 rem(28)
      min-height: rem(68)
      width: 100%
      &__icon
        padding-right: rem(28)
        transition: .5s
      @media (any-hover: hover)

        &:hover
          color: $red
          .link__icon
            opacity: .7
</style>
