<template>
  <div class="container unprint">
    <div class="nav-header">
      <div class="title common__title-result">
        Детальный расчет по участкам
        <tippy :max-width="240" placement="top">
          <template #trigger>
            <div class="title-tooltip">
              <img src="@/assets/img/question.svg" alt="?" />
            </div>
          </template>
          Нажмите на участок для просмотра его расчета
        </tippy>
      </div>
      <simple-btn color="red" @btnClicked="isEditable = !isEditable" v-if="!isDetails">
        <template #icon>
          <span class="icon">
            <img :src="editBtnKit.src" :alt="editBtnKit.text" />
          </span>
        </template>
        {{ editBtnKit.text }}
      </simple-btn>
    </div>
    <div class="row">
      <div class="sectors__links">
        <button
          @click="editSector(i)"
          class="sectors__links-item"
          :class="{ 'sectors__links-item--editable': isEditable }"
          v-for="(sector, i) in sectors"
          :key="sector.uid"
        >
          <div class="content">
            <div class="content__img">
              <nav-image :sector-index="i" :is-in-nav="true" />
            </div>
          </div>
          <div class="overlay" v-if="!isEditable">
            <b>
              Расчет<br />
              участка {{ i + 1 }}
            </b>
          </div>
        </button>
        <button class="sectors__links-add" v-if="!isDetails" @click="addSector">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.8" cx="12" cy="12" r="12" fill="#2ECC71"></circle>
            <path
              d="M12 18V6M18 12H6"
              stroke="#fff"
              stroke-width="2"
              stroke-miterlimit="22.9256"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
        <router-link to="/result/" class="sectors__links-result" v-if="isDetails">
          <svg
            width="26"
            height="29"
            viewBox="0 0 26 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 13.6667V3.66667C24 2.75 23.2929 2 22.4286 2H3.57143C2.70714 2 2 2.75 2
                      3.66667V13.6667M24 13.6667H2M24 13.6667L23.9999 25.3333C23.9999 26.2499
                      23.2928 26.9999 22.4285 26.9999L16.143 27M24 13.6667L16.143 13.6666M2
                      13.6667L9.07143 13.6666M2 13.6667V20.3333M9.07143 13.6666V20.3333M9.07143
                      13.6666H16.143M9.07143 20.3333H2M9.07143 20.3333V27M9.07143 20.3333H16.143M2
                      20.3333V25.3333C2 26.25 2.70714 27 3.57143 27H9.07143M9.07143 27H16.143M16.143
                      13.6666V20.3333M16.143 13.6666V27M16.143 20.3333V27"
              stroke="#E30713"
              stroke-width="3"
              stroke-miterlimit="22.9256"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
          <span>
            Вернуться к<br />
            полному расчету
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import SimpleBtn from '@/components/dump/SimpleBtn'
import NavImage from '@/components/dump/NavImage'

export default {
  props: {
    isDetails: Boolean
  },
  data: () => ({
    isEditable: false
  }),
  components: {
    NavImage,
    SimpleBtn
  },
  methods: {
    ...mapMutations({
      ADD_NEW_SECTOR: 'ADD_NEW_SECTOR'
    }),
    ...mapActions({
      markCalculationAsSaved: 'markCalculationAsSaved',
      resetUserInfoResults: 'resetUserInfoResults'
    }),
    addSector() {
      this.ADD_NEW_SECTOR()
      this.resetSaveCalculationResult()
      this.step = 'Base'
      this.$router.push(`/wizard/${this.sectors.length - 1}`)
    },
    editSector(index) {
      if (this.isEditable) {
        this.resetSaveCalculationResult()
        return this.$router.push(`/wizard/${index}`)
      }

      return this.$router.push(`/details/${index}`)
    },
    resetSaveCalculationResult() {
      this.resetUserInfoResults()
      this.markCalculationAsSaved(null)
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors,
      defaultSvgParams: state => state.defaultSvgParams
    }),
    editBtnKit() {
      return this.isEditable
        ? { text: 'Отменить редактирование', src: require('@/assets/img/edit-btn--cancel.svg') }
        : { text: 'Редактировать участок', src: require('@/assets/img/edit-btn.svg') }
    }
  }
}
</script>
<style lang="sass" scoped>
.nav-header
  display: flex
  +media((align-items: (320: flex-start, 650: center)))
  +media((flex-direction: (320: column, 650: row)))
  +media((justify-content: (320: flex-start, 650: space-between)))
  +media((margin: (320: rem(32) rem(-15) rem(23), 992: rem(51) rem(-15) rem(34))))
  +media((padding: (320: 0 rem(15), 768: 0 rem(24), 992: 0)))
  .title
    width: auto
    padding: 0
    +media((margin-bottom: (320: rem(15), 650: 0)))
    display: flex
    align-items: center
    +media((font-size: (320: rem(18), 68: rem(24))))
    .title-tooltip
      margin-left: rem(10)
      align-items: center
      display: flex
.sectors
  &__info
    display: flex
    flex-direction: row
    align-items: center
    +media((padding-left: (320: rem(24), 992: 0)))
    +media((margin-bottom: (320: rem(28), 768: rem(37))))
    &-text
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      color: $black
      padding-left: rem(21)
      text-align: left
      +media((line-height: (320: rem(20), 768: rem(24))))
      +media((padding-right: (320: rem(24), 768: 0)))
  &__links
    display: grid
    grid-column-gap: rem(1)
    grid-row-gap: rem(1)
    width: 100%
    +media((padding: (320: 0 rem(15), 768: 0 rem(24), 992: 0)))
    +media((grid-template-columns: (320: repeat(2, 1fr), 420: repeat(3, 1fr), 576: repeat(4, 1fr), 660: repeat(5, 1fr), 960: repeat(6, 1fr))))
    button
      border: none
    &-item
      background: $white
      text-decoration: none
      padding: rem(10) 0
      position: relative
      svg
        max-width: 100%
      .overlay
        padding: rem(20)
        height: 100%
        width: 100%
        position: absolute
        top: 0
        left: 0
        pointer-events: none
        background: rgba($red, .75)
        color: $white
        transition: .5s
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(20)
        opacity: 0
        box-sizing: border-box
      &:hover
        .overlay
          opacity: 1
      .content
        &__img
          line-height: 0
          svg
            height: auto
        // &__info
          display: flex
          flex-direction: row
          align-items: center
          justify-content: center
          font-style: normal
          font-size: rem(12)
          line-height: 1
          font-weight: 500
          &-size
            color: $black
          &-count
            color: #999999
            padding-left: rem(5)
      &--editable
        transition: .5s
        // ::v-deep .scheme-circuit
          transition: .5s
        @media (any-hover: hover)
          &:hover
            box-shadow: 0 rem(1) rem(4) rgba(51, 51, 51, 0.3), 0 rem(5) rem(30) rgba(51, 51, 51, 0.2)
            // ::v-deep .scheme-circuit
              stroke: $black
            &:before
              filter: invert(14%) sepia(100%) saturate(6377%) hue-rotate(353deg) brightness(88%) contrast(101%)
        &:before
          content: ""
          height: rem(34)
          width: rem(34)
          background-image: url("~@/assets/img/results-nav-pen.svg")
          position: absolute
          left: 0
          top: 0
          pointer-events: none
          filter: invert(97%) sepia(0%) saturate(1%) hue-rotate(173deg) brightness(89%) contrast(92%)
    &-add
      background: rgba($white, .7)
      box-shadow: none
      cursor: pointer
      border: none
      min-height: rem(100)
      margin: rem(10)
      box-sizing: border-box
      border-radius: rem(4)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      position: relative
      transition: .5s
      overflow: hidden
      outline: none
      &:after
        content: "Новый участок"
        position: absolute
        height: 100%
        width: 100%
        top: 0
        left: 0
        color: $white
        background: $green
        font-family: 'Montserrat', sans-serif
        font-style: normal
        font-weight: 700
        font-size: rem(12)
        line-height: rem(15)
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        transition: .5s
        opacity: 0
        padding: rem(50)
      &:hover
        &:after
          opacity: 1
    &-result
      background: rgba($white, .7)
      box-shadow: none
      cursor: pointer
      border: none
      min-height: rem(108)
      margin: rem(10)
      box-sizing: border-box
      border-radius: rem(4)
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      position: relative
      transition: .5s
      overflow: hidden
      outline: none
      text-decoration: none
      font-style: normal
      font-weight: 500
      font-size: rem(12)
      line-height: rem(20)
      color: $red
      svg
        margin-bottom: rem(14)
        path
          transition: .5s
      &:hover
        background: $red
        color: $white
        font-weight: bold
        svg
          path
            stroke: $white
</style>
