<template>
  <button :class="classes" @click="handler">
    <slot name="icon"></slot>
    <span class="simple-btn__text">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'red',
      required: true,
      validator: v => ['red', 'grey', 'transparent'].includes(v)
    },
    additionalClasses: {
      type: Array,
      default: () => []
    }
  },
  name: 'SimpleBtn',
  methods: {
    handler() {
      this.$emit('btnClicked')
    }
  },
  computed: {
    classes() {
      return ['simple-btn', `simple-btn--${this.color}`, ...this.additionalClasses]
    }
  }
}
</script>

<style scoped lang="sass">
.simple-btn
  @extend %clear-btn
  font-style: normal
  font-weight: bold
  font-size: rem(14)
  line-height: 100%
  display: inline-flex
  align-items: center
  min-height: rem(50)
  transition: .5s
  border-radius: rem(4)
  +media((padding: (320: rem(14) rem(18), 768: rem(14) rem(26))))
  .icon
    +media((margin-right: (320: rem(15), 768: rem(20))))
    transition: .5s
  &--red
    color: $white
    background: $red
    @media(any-hover: hover)
      &:hover
        background: $red-hover
  &--grey
    color: $light-black
    background:  $semi-white
    @media(any-hover: hover)
      &:hover
        background: $white
  &--transparent
    @media(any-hover: hover)
      &:hover
        background: $white
</style>
