<template>
  <div class="prefooter" ref="prefooter">
    <div :class="wrapperClasses">
      <a class="prefooter__buttons-btn prefooter__buttons-btn--save"
         title="Перейти в Личный кабинет для привязки расчета"
         @click="toCalculationLink()"
      >
        <img class="img" src="@/assets/img/cloud.svg" alt="save" />
        <p class="content">
          <span class="content__title">Сохранить расчет</span>
          <span class="content__text">
            Все параметры и расчетные данные будут сохранены и доступны по ссылке
          </span>
        </p>
      </a>
      <div
        @click="savePdf"
        class="prefooter__buttons-btn prefooter__buttons-btn--download"
        v-if="isInDetails"
      >
        <svg width="22" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.857 1v5.778c0 .383.15.75.419 1.021.268.271.63.423 1.01.423H21M13.857 1h-10c-.758 0-1.484.304-2.02.846A2.905 2.905 0 001 3.89V24.11c0 .766.301 1.501.837 2.043a2.842 2.842 0 002.02.846h14.286c.758 0 1.484-.304 2.02-.846A2.905 2.905 0 0021 24.11V8.222M13.857 1L21 8.222m-10 4.334v8.666m0 0L6.714 16.89M11 21.222l4.286-4.333"
            stroke="#E30713"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="title">
          Скачать расчет
          <b>PDF</b> <br />
          по участку
        </div>
      </div>
      <div @click="savePdfAll" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <svg width="22" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.857 1v5.778c0 .383.15.75.419 1.021.268.271.63.423 1.01.423H21M13.857 1h-10c-.758 0-1.484.304-2.02.846A2.905 2.905 0 001 3.89V24.11c0 .766.301 1.501.837 2.043a2.842 2.842 0 002.02.846h14.286c.758 0 1.484-.304 2.02-.846A2.905 2.905 0 0021 24.11V8.222M13.857 1L21 8.222m-10 4.334v8.666m0 0L6.714 16.89M11 21.222l4.286-4.333"
            stroke="#E30713"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="title">
          Скачать расчет <b>PDF</b> <br />
          общий
        </div>
      </div>
      <div @click="print" class="prefooter__buttons-btn prefooter__buttons-btn--download">
        <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.222 21.222h2.89A2.889 2.889 0 0027 18.333v-5.777a2.89 2.89 0 00-2.889-2.89H3.89A2.889 2.889 0 001 12.557v5.777a2.889 2.889 0 002.889 2.89h2.889M21.222 9.666V3.889A2.889 2.889 0 0018.333 1H9.667a2.889 2.889 0 00-2.89 2.889v5.778m2.89 5.777h8.666a2.889 2.889 0 012.89 2.89v5.777A2.889 2.889 0 0118.332 27H9.667a2.889 2.889 0 01-2.89-2.889v-5.778a2.889 2.889 0 012.89-2.889z"
            stroke="#E30713"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="title">Распечатать <br />расчет</div>
      </div>
      <div
        @click="toggleConfirmationModal"
        class="prefooter__buttons-btn prefooter__buttons-btn--new"
      >
        <img src="@/assets/img/prefooter--new-calculation.svg" alt="new" />
        <div class="title">
          <b>Начать <br />новый расчет</b>
        </div>
      </div>
      <confirmation-modal :is-modal-open="isModalOpen" @close="toggleConfirmationModal">
        <template #text> Вы желаете сохранить параметры и результаты текущего расчета? </template>
        <template #cancel>
          <button v-if="!saveCalculationLink" class="btn btn--red" @click="toCalculationLink()">
            <img src="@/assets/img/cloud--mini.svg" alt="cloud" class="icon" />
            Сохранить расчет
          </button>
        </template>
        <template #confirm>
          <button class="btn btn--green" @click="startNewCalculation">
            <img src="@/assets/img/new-calculation.svg" alt="new" class="icon" />
            Новый расчет
          </button>
        </template>
      </confirmation-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { prepareSectorsForPdf, prepareTotalMaterialsTableForPdf } from '@/utils/pdf'
import { getFullResultsPdf } from '@/api'
import { isSafari } from '@/utils/utils'
import { saveAs } from 'file-saver'
import ConfirmationModal from '@/components/dump/ConfirmationModal'
import { sendStatistic, statisticLabels } from '@/utils/sendStatistic'

export default {
  props: {
    isInDetails: Boolean
  },
  data: () => ({
    isModalOpen: false
  }),
  methods: {
    ...mapActions({
      startNewCalculation: 'startNewCalculation'
    }),
    toggleConfirmationModal() {
      this.isModalOpen = !this.isModalOpen
    },
    print() {
      sendStatistic(statisticLabels.prefooter.print)
      window.print()
    },
    savePdf() {
      this.$emit('savePdf')
    },
    savePdfAll() {
      // dataLayer.push({ event: 'calc-roofload-download-complex' })
      this.$emit('toggleLoading', true)
      const count = this.result.count ?? null
      // const ballast = this.result.ballast ?? null
      const sectorsArray = prepareSectorsForPdf(
        this.location,
        this.sectors,
        this.resultSectors,
        this.insulationMaterials,
        this.allMaterials,
        this.mountingTypes
      )
      const materialsTable = prepareTotalMaterialsTableForPdf(
        this.result.materials,
        this.sectors,
        this.allMaterials
      )
      getFullResultsPdf(
        this.userInfo,
        this.result,
        count,
        this.location,
        sectorsArray,
        materialsTable,
        this.$store.getters.getDisclaimer,
        this.publicCalculationLink
      )
        .then(response => {
          this.$emit('toggleLoading', false)
          if (process.env.NODE_ENV === 'development') {
            console.log(response)
          }
          // Create a Blob from the PDF Stream
          const file = new Blob([response.data], { type: 'application/pdf' })
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file)
          // Open the URL on new Window
          if (this.isSafari) {
            const fileName = 'Детальный расчет по участкам.pdf'
            saveAs(file, fileName)
          } else {
            window.open(fileURL)
          }
          sendStatistic(statisticLabels.prefooter.pdf)
        })
        .catch(error => {
          console.log(error)
        })
    },
    toCalculationLink() {
      if (!this.saveCalculationLink) {
        sendStatistic(statisticLabels.prefooter.save)
        window.parent.postMessage({ resultId: this.userInfo.result?.id }, '*')
        window.top.location = this.userInfo.result?.link
      } else {
        window.parent.postMessage({ scrollTop: true }, '*')
        this.$notify({
          group: 'foo',
          type: 'warn',
          title: 'Повторное сохранение не требуется. Изменения в расчёте отсутствуют',
          text: require('@/assets/img/notify.svg')
        })
      }
    }
  },
  beforeDestroy() {
    this.$root.onBodyScroll()
  },
  components: { ConfirmationModal },
  computed: {
    ...mapState({
      result: state => state.result.total,
      resultSectors: state => state.result.sectors,
      insulationMaterials: state => state.insulationMaterials,
      allMaterials: state => state.materials,
      sectors: state => state.sectors,
      location: state => state.location,
      userInfo: state => state.userInfo,
      mountingTypes: state => state.mounting
    }),
    ...mapGetters({
      publicCalculationLink: 'publicCalculationLink'
    }),
    isSafari() {
      return isSafari()
    },
    wrapperClasses() {
      return {
        prefooter__buttons: true,
        'prefooter__buttons--result': !this.isInDetails
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.prefooter
  background: $white

  &__buttons
    display: grid
    $t-xl: 20fr repeat(3, 9fr) 9fr
    $t-l: repeat(3, 1fr)
    $t-m: repeat(4, 1fr)
    $t-s: 1fr 1fr
    +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
    max-width: rem(1120)
    width: 100%
    +media((margin: (320: rem(48) auto 0, 960: rem(68) auto 0)))
    &--result
      $t-xl: 20fr repeat(2, 9fr) 9fr
      $t-l: repeat(3, 1fr)
      $t-m: repeat(3, 1fr)
      $t-s: 1fr 1fr
      +media((grid-template-columns: (320: $t-s, 576: $t-m, 768: $t-l, 960: $t-xl)))
    &-btn
      min-height: rem(153)
      &:not(:last-child)
        +media((box-shadow: (768: inset rem(-1) 0 0 $ec)))
      &--download, &--new
        color: $black
        background: $white

        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        transition: .5s
        cursor: pointer
        text-decoration: none

        .title
          font-weight: normal
          font-size: rem(14)
          line-height: 130%
          text-align: center

        svg
          path
            transition: .5s
        @media(any-hover: hover)
          &:hover
            background: $red
            color: $white
            svg
              path
                stroke: $white
      &--download
        .title
          margin-top: rem(26)
      &--new
        +media((grid-area: (320: '2 / 2 / 3 / 3', 576: '2 / 4 / 2 / 5', 768: '2 / 1 / 3 / 2', 960: auto)))
        .title
          margin-top: rem(14)
      &--save
        +media((grid-column: (320: '1 / 3', 576: '1 / 5', 768: '1 / 4', 960: auto)))
        +media((padding: (320: 0 rem(25), 768: 0 rem(40) 0 0)))
        background: $red
        display: flex
        flex-direction: row
        align-items: center
        text-decoration: none
        transition: .5s
        position: relative
        cursor: pointer
        @media(any-hover: hover)
          &:hover
            background: $red-hover
        .img
          pointer-events: none
          +media((padding: (320: 0 rem(24) 0 0, 768: 0 rem(38), 960: 0 rem(18), 1120: 0 rem(38))))
        .content
          display: flex
          flex-flow: row wrap
          margin: 0
          color: $white
          text-align: left
          pointer-events: none
          &__title
            width: 100%
            font-style: normal
            font-weight: bold
            margin-bottom: rem(12)
            +media((font-size: (320: rem(20), 960: rem(18))))
            +media((line-height: (320: rem(24), 960: rem(22))))
          &__text
            font-style: normal
            font-weight: 600
            font-size: rem(12)
            line-height: 150%
    &--result
      .prefooter
        &__buttons
          &-btn
            &--new
              +media((grid-area: (320: '2 / 1 / 3 / 3', 576: '3 / 3 / 2 / 4', 768: '3 / 3 / 2 / 4', 960: auto)))
            &--save
              +media((grid-column: (320: '1 / 3', 576: '1 / 4', 768: '1 / 4', 960: auto)))
</style>
